@use 'base/variables' as v;

.site-footer {
    background-color: v.$grisOscuro;
    padding: 1rem;
    p {
        margin: 0;
        text-align: center;
        color: v.$blanco;
        font-size: 1.5rem;
        font-weight: 100;
    }
}