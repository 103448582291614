@use 'base/variables' as v;
@use 'base/mixins' as m;

.logo_mixto {
    max-width: 30rem;
    margin: 3rem auto;
}
.gracias {
    h1,h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
        color: v.$rojo;
        font-weight: 400;
    }
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.6rem;
        font-weight: 700;
        margin-bottom: 0;
    }
    p {
        text-align: center;
    }
    .paso {
        max-width: 60rem;
        margin: 0 auto;
    }
    .boton {
        background-color: v.$rojo;
        font-size: 3rem;
        color: v.$blanco;
        max-width: 28rem;
        display: block;
        margin: 1rem auto 3rem auto;
        font-family: v.$fuente_titulo;
        text-align: center;
        padding: 1rem 3rem;
        border-radius: 1rem;
        &:hover {
            background-color: v.$negro;
            color: v.$blanco;
        }
    }
    .linea {
        border-bottom: solid thin v.$grisClaro;
        height: .5rem;
        margin: 4rem auto;
        max-width: 65rem;
        display: block;
    }
}
.dirigido {
    h1,h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
    }
    h1 {
        font-size: 3.5rem;
        font-weight: 400;
        margin-top: 2rem;
        margin-bottom: 0;
        color: v.$rojo;
    }
    h2 {
        font-size: 3.2rem;
        font-weight: 100;
        margin-bottom: 0;
        color: v.$grisOscuro;
        margin-bottom: 3rem;
    }
}
.max_400 {
    max-width: 40rem;
    margin: 2rem auto;
}