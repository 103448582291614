@use 'base/variables' as v;
@use 'base/mixins' as m;

.metodologia {
    padding: 0 0 5rem 0;
    background-color: v.$negro;
    background-image: url(../img/masajes-intimos.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 5rem 0;
    .duracion {
        font-family: v.$fuente_titulo;
        color: v.$blanco;
        text-align: center;
        strong {
            color: v.$rojo;
            font-size: 4rem;
        }
    }
    .fijo {
       
        .fijo_a {
         
        }
        h2 {
            font-family: v.$fuente_titulo;
            color: v.$rojo;
            text-align: center;

        }
    }
    .metodologia_ {
        
        h3  {
            font-family: v.$fuente_titulo;
            color: v.$blanco;
            margin-bottom: 0;
            background-color: rgb(0 0 0 / 45%);
            display: inline-block;
            padding: 1rem 2rem;
            border-radius: 1.5rem;
        }
        p {
            color: v.$blanco;
            background-color: rgb(0 0 0 / 45%);
            padding: 1rem 2rem;
            border-radius: 1.5rem;
        }
        @include m.tablet {
            @include m.grid(3, 5rem);
        }
    }
    .puntos {
        h3 {
            color: v.$rojo;
            margin-bottom: 1rem;
        }
        li {
            color: v.$blanco;
            font-family: v.$fuente_titulo;
            font-weight: 100;
            font-size: 2.8rem;
            margin-bottom: 1rem;
        }
    }
}