@use 'base/variables' as v;
@use 'base/mixins' as m;

.header_ {
    background-image: url(../img/fondo.jpg);
    background-color: v.$negro;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem 0;
    .header_div {
        @include m.tablet {
            padding-left: 5rem;
        }
    }
    .fecha {
            background-color: v.$rojo;
            padding: 2rem;
            color: v.$blanco;
            font-size: 2rem;
            text-align: center;
            font-family: v.$fuente_titulo;
            @include m.tablet {
                display: block;
                width: 28rem;
            }
    }
    .lugar {
        background-color: v.$grisOscuro;
        padding: 2rem;
        color: v.$blanco;
        font-size: 2rem;
        text-align: center;
        font-family: v.$fuente_titulo;
        font-weight: 100;
        @include m.tablet {
            display: block;
            width: 28rem;
        }
    }
    .logo_mixto {
        margin: 0 auto 3rem 0;
        max-width: 30rem;
    }
}
.header {
    background-color: v.$negro;
    font-family: v.$fuente_titulo;
    font-weight: 200;
    @include m.tablet {
        &.fijo {
            left: 0;
            top: 0;
            position: fixed;
            width: 100%;
            box-shadow: .1rem .2rem .3rem #333;
        }
    }

    h1 {
        color: v.$blanco;
        text-align: center;
        @include m.tablet {
            margin: 0;
        }
        
    }
    .contenido-header {
        padding: 2rem;
        @include m.tablet {
            display: flex;
            justify-content:space-between;
            align-items: center;
        }
    }
    
}

.navegacion-principal {
    @include m.tablet {
        display: flex;
        gap: 2rem;
    }
    a {
        display: block;
        text-align: center;
        color: v.$blanco;
        font-size: 2.4rem;
        margin-bottom: 1rem;
        @include m.tablet {
            margin: 0;
        }

        &:hover {
            color: v.$amarillo;
        }
    }
}
