// Tipografia
$fuente_principal: Arial, Helvetica, sans-serif;
$fuente_titulo: 'Oswald', sans-serif;

// Colores
$verde:#4CB8B3;
$rosa: #F53756;
$rojo: #cc0000;
$amarillo: #FEF46D;
$morado: #752F97;
$negro: #000000;
$blanco: #FFFFFF;
$grisOscuro: #333333;
$grisClaro: #ebebeb;
// Tamaños de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;