@use 'base/variables' as v;
@use 'base/mixins' as m;

.instalaciones {
    background-image: linear-gradient(to right top, #dbdbdb, #e2e2e2, #e8e8e8, #efefef, #f6f6f6, #f2f2f2, #eeeeee, #eaeaea, #dbdbdb, #cccccc, #bdbdbd, #afafaf);
    padding: 5rem 0;
    .instalacion {
        div {
            padding: 2rem 0;
        }
        @include m.tablet {
            @include m.grid(2, 5rem);
        }
    }
    h2 {
        color: v.$rojo;
        text-align: center;
        font-family: v.$fuente_titulo;
        font-weight: 400;
        margin-bottom: 0;
    }
    p {
        text-align: center;
    }
}