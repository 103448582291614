@use 'base/variables' as v;
@use 'base/mixins' as m;

.sobre-festival {
    padding: 5rem 0;
    .max_300 {
        max-width: 30rem;
        margin: 3rem auto;
    }
    .max_400 {
        max-width: 40rem;
        margin: 3rem auto;
    }
    @include m.tablet {
        @include m.grid(2, 5rem);
    }
}

.contenido-festival {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
    @include m.tablet {
        margin-top:0;
    }
    h2 {
        margin-bottom: 2rem;
        font-family: v.$fuente_titulo;
        color: v.$grisOscuro;
        text-align: center;
        font-weight: 400;
        span {
            color: v.$rojo;
            font-weight: 100;
        }
    }
    .fecha {
        color: v.$rosa;
        font-weight: 700;
    }
    p {
        color: v.$grisOscuro;
        font-size: 2rem;
        line-height: 1.7;
    }
}