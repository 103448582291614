@use 'base/variables' as v;
@use 'base/mixins' as m;

.horarios {
    padding: 5rem 0;
    h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
        color: v.$rojo;
    }
}
.horario {
    max-width: 80rem;
    .dia {
        background-color: v.$rojo;
        padding: 1rem .5rem;
        text-align: center;
        font-size: 2rem;
        font-family: v.$fuente_titulo;
        color: v.$blanco;
    }
    .row_dia {
        .actividad {
            font-size: 2rem;
            margin-top: 1rem;
            padding-left: 2rem;
        }
        .hora {
            font-style: italic;
            margin-bottom: 2rem;
            border-bottom: solid thin v.$grisClaro;
            padding-left: 2rem;
        }
    }
}