@use 'base/variables' as v;
@use 'base/mixins' as m;

.precio {
    padding: 5rem 0;
    h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
        margin: 0;
    }
    h3 {
        font-family: v.$fuente_titulo;
        text-align: center;
        margin: 0;
    }
    .boton {
        margin: 2rem;
        background-color: v.$negro;
        padding: 2rem 3rem;
        display: block;
        text-align: center;
        color: v.$blanco;
        font-size: 2rem;
        font-weight: bold;
        font-family: v.$fuente_titulo;
        &:hover {
            background-color: v.$rojo;
        }
    }
}
.fecha {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: v.$fuente_titulo;
}