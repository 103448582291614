@use 'base/variables' as v;
@use 'base/mixins' as m;

.objetivos {
    padding: 5rem 0;
    /*background-image: linear-gradient(to right top, #e41189, #e5399a, #e551aa, #e565b9, #e477c7, #df75c4, #db74c2, #d672bf, #cc5dac, #c24799, #b82e86, #ad0272);
    background-image: linear-gradient(to right top, #c6057e, #ce008e, #d500a0, #da00b3, #dd00c7, #da00c4, #d800c1, #d500be, #cb00a4, #bf008d, #b10077, #a10a64);*/
    background-image: linear-gradient(to right top, #a7a7a7, #b4b4b4, #c0c0c0, #cdcdcd, #dadada, #d8d8d8, #d5d5d5, #d3d3d3, #c1c1c1, #afafaf, #9e9e9e, #8d8d8d);
}

.objetivo {
    h3 {
        font-family: v.$fuente_titulo;
        text-align: center;
        font-size: 3.2rem;
        font-weight: 100;
        margin-bottom: 0;
        color: v.$rojo;
        margin-bottom: 3rem;
    }
    li {
        padding-bottom: 1rem;
        font-size: 2rem;
        font-weight: 100;
        font-family: v.$fuente_titulo;
    }
    @include m.tablet {
        @include m.grid(3, 5rem);
    }
}
.objetivo_1 {
    h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
        font-size: 3.2rem;
        font-weight: 100;
        margin-bottom: 0;
        color: v.$rojo;
        margin-bottom: 1rem;
    }
    @include m.tablet {
        .taller p {
            max-width: 80rem;
            margin: 0 auto 3rem auto;
        }
    }
}
.objetivo_2 {
    h2 {
        font-family: v.$fuente_titulo;
        text-align: center;
        font-size: 3.2rem;
        font-weight: 400;
        margin-bottom: 0;
        color: v.$rojo;
        margin-bottom: 3rem;
    }
    .mujer {
        background-color: v.$rojo;
        color: v.$blanco;
        padding: 1rem 0;
        margin-bottom: 0;
    }
    .hombre {
        background-color: v.$negro;
        color: v.$blanco;
        padding: 1rem 0;
        margin-bottom: 0;
    }
    .uno {
        background-color: v.$blanco;
        padding: 1rem;
    }
    .dos {
        background-color: v.$grisClaro;
        padding: 1rem;
    }
    .espacio {
        padding-left: 2rem;
    }
    @include m.tablet {
        @include m.grid(2, 5rem);
        max-width: 80rem;
        margin: 2rem auto;
    }
}
.inversion {
    max-width: 50rem;
    .mujer {
        background-color: v.$rojo;
        color: v.$blanco;
        padding: 1rem 0;
        margin-bottom: 0;
    }
    .hombre {
        background-color: v.$negro;
        color: v.$blanco;
        padding: 1rem 0;
        margin-bottom: 0;
    }
    .uno {
        background-color: v.$blanco;
        padding: 1rem;
    }
    .dos {
        background-color: v.$grisClaro;
        padding: 1rem;
    }
}